import '@/assets/scss/main.scss';
import Vue from 'vue';
import globalProperties from '@/plugins/globalProperties';
import i18n from './locales';
import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
  i18n,
  globalProperties,
  render: (h) => h(App),
}).$mount('#app');
