<template>
  <div id="app">
    <div
      class="cr"
      :style="[{backgroundImage: `url(${bgImg})`}]"
    >
      <transition name="fade">
        <div v-if="isToastShow" class="toast">
          <img class="toast-icon" src="@/assets/img/warning.svg" alt="">
          <span class="toast-text">{{ toastMsg }}</span>
        </div>
      </transition>

      <!-- 手機的header -->
      <header class="header">
        <img
          :src="$requireSafe(`${siteId}/logo.svg`)"
          alt="logo"
        >
      </header>

      <div class="pc">
        <div class="bg-url">
          <img :src="$requireSafe(`${siteId}/bg-text.svg`)" alt="bg-text">
        </div>

        <danmu :site-id="siteId" />

        <div class="view">
          <div class="phone">
            <img :src="$requireSafe(`${siteId}/phone.webp`)" alt="phone">
          </div>

          <div class="middle">
            <div class="sign">
              <div class="sign-img">
                <img :src="$requireSafe(`${siteId}/sign.png`)" alt="sign">
              </div>

              <div class="sign-text">
                <div class="sign-text-top">
                  {{ `${renderHostName}${$t('spokesperson')}` }}
                </div>

                <div class="sign-text-bottom">{{ $t('spokesperson1') }}</div>
              </div>
            </div>

            <div class="people">
              <img :src="$requireSafe(`${siteId}/people.png`)" alt="people">
            </div>
          </div>

          <div class="right">
            <div class="right-top">
              <div class="right-top-logo">
                <img :src="$requireSafe(`${siteId}/logo.svg`)" alt="logo">
              </div>

              <div class="right-top-text">
                <div class="first">{{ $t('text1') }}</div>
                <div class="second">{{ `${$t('text2')}${renderHostName}` }}</div>
                <div class="third">
                  <div>{{ $t('text3') }}</div>
                  <div>{{ $t('text4') }}</div>
                </div>
              </div>

              <div class="right-top-button" @click="goUrl(2)">
                {{ $t('rightNow') }}
              </div>
            </div>

            <div class="right-bottom">
              <div class="right-bottom-first first">
                <div
                  v-for="item in firstButtomList"
                  :key="item.key"
                  class="right-bottom-first-item"
                  :class="{'tool-tip-button': item.key === 0}"
                  @click="item.key === 0 ? toggleTooltip(!isShowTooltip) : goUrl(item.key)"
                >
                  <div class="icon">
                    <img :src="$requireSafe(`${siteId}/${item.img}`)" :alt="item.label2">
                  </div>

                  <div class="text">
                    <div class="text-first">{{ $t(item.label) }}</div>
                    <div class="text-second">{{ item.label2 }}</div>
                  </div>
                </div>

                <div
                  v-show="isShowTooltip"
                  class="tooltip"
                >
                <template v-if="urlList && urlList.length">
                  <div
                    v-for="(item, idx) in urlList"
                    :key="`pingArr[${idx}]`"
                    class="row"
                  >
                    <div class="square ping fz-sm">
                      {{ item && item.ping }}ms
                    </div>

                    <img
                      class="arrow"
                      :src="require('@/assets/img/arrow.svg')"
                      alt=""
                    >

                    <div class="square url fz-sm">
                      {{ formatUrl(item && item.url) }}
                    </div>

                    <a
                      href="javascript:void(0)"
                      class="square enter fz-sm"
                      :class="{ 'enter--disabled': item && item.disabled }"
                      @click="goWebsite(item && item.url, item && item.disabled)"
                    >
                      {{ item && item.disabled ? $t('close') : $t('enter') }}
                    </a>
                  </div>
                </template>
                </div>
              </div>

              <div class="right-bottom-first">
                <div
                  v-for="item in secondButtomList"
                  :key="item.key"
                  class="right-bottom-first-item"
                  @click="goUrl(item.key)"
                >
                  <div class="icon">
                    <img :src="$requireSafe(`${siteId}/${item.img}`)" :alt="item.label2">
                  </div>

                  <div class="text">
                    <div class="text-first">{{ $t(item.label) }}</div>
                    <div class="text-second">{{ item.label2 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="footer-left">
            <div class="first">
              <img :src="$requireSafe(`${siteId}/ball.svg`)" alt="ball">
            </div>
            <div class="second">
              <img :src="$requireSafe(`${siteId}/trophy.png`)" alt="trophy">
            </div>
            <div class="third">
              <img :src="$requireSafe(`${siteId}/fiba.svg`)" alt="fiba">
            </div>

            <div class="text">
              <div>{{ $t('footerText1') }}</div>
              <div>{{ $t('footerText2') }}</div>
            </div>
          </div>

          <div class="footer-right">
            <div>{{ $t('copyright', {renderHostName: renderHostName}) }}</div>
            <div>{{ $t('copyright2') }}</div>
          </div>
        </div>
      </div>

      <div class="mobile">
        <danmu :site-id="siteId" />

        <div class="view">
            <div class="sign">
              <div class="sign-img">
                <img :src="$requireSafe(`${siteId}/sign.png`)" alt="sign">
              </div>

              <div class="sign-text">
                <div class="sign-text-top">
                  {{ `${renderHostName}${$t('spokesperson')}` }}
                </div>

                <div class="sign-text-bottom">{{ $t('spokesperson1') }}</div>
              </div>

              <div class="sign-logo">
                <div class="first">
                  <img :src="$requireSafe(`${siteId}/ball.svg`)" alt="ball">
                </div>
                <div class="second">
                  <img :src="$requireSafe(`${siteId}/trophy.png`)" alt="trophy">
                </div>
                <div class="third">
                  <img :src="$requireSafe(`${siteId}/fiba.svg`)" alt="fiba">
                </div>
              </div>
            </div>

            <div class="people">
              <img :src="$requireSafe(`${siteId}/people.png`)" alt="people">
            </div>
        </div>

        <div class="footer" :style="{backgroundImage: `url(${footerImg})`}">
          <div class="footer-top">
            <div class="one">{{ $t('text1') }}</div>
            <div class="two">{{ `${$t('text2')}${renderHostName}` }}</div>
          </div>

          <div class="footer-middle">
            <div class="footer-middle-first first">
              <div
                v-for="item in firstButtomList"
                :key="item.key"
                class="footer-middle-first-item"
                :class="{'tool-tip-button': item.key === 0}"
                @click="item.key === 0 ? toggleTooltip(!isShowTooltip) : goUrl(item.key)"
              >
                <div class="icon">
                  <img :src="$requireSafe(`${siteId}/${item.img}`)" :alt="item.label2">
                </div>

                <div class="text">
                  <div class="text-first">{{ $t(item.label) }}</div>
                  <div class="text-second">{{ item.label2 }}</div>
                </div>
              </div>

              <div
                v-show="isShowTooltip"
                class="tooltip"
              >
                <template v-if="urlList && urlList.length">
                <div
                  v-for="(item, idx) in urlList"
                  :key="`pingArr[${idx}]`"
                  class="row"
                >
                  <div class="square ping fz-sm">
                    {{ item.ping }}ms
                  </div>

                  <img
                    class="arrow"
                    :src="require('@/assets/img/arrow.svg')"
                    alt=""
                  >

                  <div class="square url fz-sm">
                    {{ item.url }}
                  </div>

                  <a
                    href="javascript:void(0)"
                    class="square enter fz-sm"
                    :class="{ 'enter--disabled': item.disabled }"
                    @click="goWebsite(item.url, item.disabled)"
                  >
                    {{ item.disabled ? $t('close') : $t('enter') }}
                  </a>
                </div>
                </template>
              </div>
            </div>

            <div class="footer-middle-first">
              <div
                v-for="item in secondButtomList"
                :key="item.key"
                class="footer-middle-first-item"
                @click="goUrl(item.key)"
              >
                <div class="icon">
                  <img :src="$requireSafe(`${siteId}/${item.img}`)" :alt="item.label2">
                </div>

                <div class="text">
                  <div class="text-first">{{ $t(item.label) }}</div>
                  <div class="text-second">{{ item.label2 }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-bottom">
            {{ `${$t('copyright', {renderHostName: renderHostName})} ${$t('copyright2')}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteApi from '@/assets/js/api/siteApi.js';
import danmu from '@/danmu.vue';

const maxCount = 10;
const $requireSafe = (path) => {
  try {
    /* eslint-disable */
    return require(`@/assets/img/${path}`);
  } catch (err) {
    return undefined;
  }
};

export default {
  name: 'App',
  components: {
    danmu,
  },
  data() {
    return {
      firstButtomList: [
        {
          label: 'homePage', label2: 'ENTER INDEX', img: 'home.svg', key: 0,
        },
        {
          label: 'download', label2: 'APP DOWNLOAD', img: 'download.svg', key: 1,
        },
      ],
      secondButtomList: [
        {
          label: 'register', label2: 'SIGN UP NOW', img: 'register.svg', key: 2,
        },
        {
          label: 'service', label2: 'ONLINE SERVICE', img: 'service.svg', key: 3,
        },
      ],
      isShowTooltip: false,
      urlList: [],
      appUrl: '',
      serviceUrl: '',
      locale: '',
      siteId: '',
      sharedUrl: '',
      indexList: [],
      isMobile: false,
      toastMsg: '',
      isToastShow: false,
      randoms: []
    };
  },
  computed: {
    containerHeight() {
      const vm = this;
      if (!vm.isMobile || !vm.isShowTooltip) return {};
      return {
        minHeight: '700px',
      };
    },
    renderHostName() {
      const list = {
        66001: '365777.com',
      };
      return list[this.siteId] ?? '';
    },
    bgImg() {
      const vm = this;
      return $requireSafe(`${vm.siteId}/bg-sm.png`);
    },
    footerImg() {
      const vm = this;
      return $requireSafe(`${vm.siteId}/bg-footer.svg`);
    },
  },
  watch: {
    siteId() {
      const vm = this;
      vm.setTitle();
    },
  },
  async created() {
    const vm = this;
    const { siteId, siteLocale } = window._jsvar;
    vm.siteId = siteId;
    vm.locale = siteLocale;
    vm.$i18n.locale = siteLocale;

    vm.setUrlList();
    await vm.getPingArr();
    await vm.getServiceAndAppUrl();
  },
  mounted() {
    const vm = this;
    vm.handleResize();
    window.addEventListener('resize', vm.handleResize);
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener('resize', vm.handleResize);
  },
  methods: {
    setTitle() {
      const vm = this;
      const list = {
        66001: '365777.com',
      };
      document.title = list[vm.siteId] ?? '';
      const favicon = document.getElementById('favicon');
      favicon.href = `site/${vm.siteId}/favicon.ico`;
    },
    showToast(msg, duration = 3000) {
      const vm = this;
      vm.toastMsg = msg;
      vm.isToastShow = true;
      setTimeout(() => {
        vm.isToastShow = false;
        vm.toastMsg = '';
      }, duration);
    },
    getRandomNum() {
      const vm = this;

      while(vm.randoms.length < 10) {
        const randomNum = Math.floor(Math.random() * window._jsvar?.siteDomainList?.length);

        if (!vm.randoms.includes(randomNum)) {
          vm.randoms.push(randomNum)
        }
      }
    },
    setUrlList() {
      const vm = this;
      const { siteDomainList } = window._jsvar;
      const allList = siteDomainList.map((item) => ({
        url: item,
        ping: '10',
        disabled: false,
      }));

      vm.getRandomNum();

      vm.randoms.forEach((item) => {
        vm.urlList.push(allList[item])
      })

    },
    checkDevice() {
      const vm = this;
      vm.isMobile = window.matchMedia('(max-width: 768px)').matches;
    },
    handleFullHeight() {
      const vm = this;
      const vh = window.innerHeight * 0.01;
      document.querySelector('.cr').style.setProperty('--vh', `${vh}px`);
      vm.fullVh = vh * 100;
    },
    handleResize() {
      const vm = this;
      vm.handleFullHeight();
      vm.checkDevice();
    },
    getPingArr() {
      const vm = this;

      const storagePingArr = [];
      vm.urlList.forEach(() => {
        storagePingArr.push([]);
      });
      vm.urlList.forEach((item, index) => {
        const loadTimer = setInterval(async () => {
          const { time, disabled } = await vm.getLoadTime(`${item.url}/favicon.ico`);
          storagePingArr[index].push(time);
          vm.$set(item, 'ping', time);
          vm.$set(item, 'disabled', disabled);
          vm.indexList.push(index);
          if (storagePingArr[index].length === maxCount) {
            for (let i = 0; i < 2; i++) {
              const maxPing = Math.max.apply(null, storagePingArr[index]);
              storagePingArr[index].splice(storagePingArr[index].indexOf(maxPing), 1);
            }
            const finalPing = vm.getArrayAvg(storagePingArr[index]);
            item.ping = Math.round(finalPing);

            clearInterval(loadTimer);
            if (index === vm.indexList[vm.indexList.length - 1] && vm.indexList.length === maxCount * vm.urlList.length) {
              vm.urlSort();
            }
          }
        }, 1000);
      });
    },
    getLoadTime() {
      return new Promise(((resolve) => {
        // const img = new Image();
        const timeStart = new Date();
        // img.src = url;
        // img.onerror = function run() {
          // const timeEnd = new Date();
          // resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: true });
        // };
        // img.onload = function run() {
        const timeEnd = new Date();
        resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: false });
        // };
      }));
    },
    urlSort() {
      const vm = this;
      vm.urlList.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.ping - b.ping;
        }

        return a.disabled - b.disabled;
      });
    },
    getArrayAvg(array) {
      const len = array.length;
      let sum = 0;
      for (let i = 0; i < len; i++) {
        sum += array[i];
      }
      return sum / len;
    },
    async getServiceAndAppUrl() {
      const vm = this;
      const urlList = vm.urlList.filter((e) => !e.disabled);
      for (let i = 0; i < vm.urlList.length; i++) {
        SiteApi.SERVER = `${urlList[i].url}/api`;
        vm.sharedUrl = `${urlList[i].url}?a=x&c=`;
        // 需求是 call api 不如預期才進下一個迭代，故 disable eslint 的限制
        // eslint-disable-next-line
        const [service, app] = await Promise.all([SiteApi.getServiceUrl(vm.locale), SiteApi.getAppUrl(vm.locale)])

        if (service.code === 200 && app.code === 200) {
          vm.serviceUrl = service.data.serviceUrl.Online.links;
          vm.appUrl = app.data.link;

          break;
        }


        if (i === vm.urlList.length - 1 && service.code !== 200) {
          vm.showToast('Try again later');
        }
      }
    },
    toggleTooltip(isShow) {
      const vm = this;
      vm.isShowTooltip = isShow;
    },
    goWebsite(item, disabled) {
      if (disabled) return;

      if(window._jsvar?.siteAgentCode) {
        window.location = `${item}?a=x&c=${window._jsvar?.siteAgentCode}`
      } else if (window._jsvar?.siteRedirectRegister) {
        window.location.href = `${item}?a=x`;
      } else {
        window.location.href = item;
      }
    },
    goUrl(item) {
      const vm = this;
      let url;
      switch (item) {
        case 1:
          url = window._jsvar?.siteAppUrl || vm.appUrl;
          break;
        case 2:
          url = `${vm.sharedUrl}${window._jsvar?.siteAgentCode || ''}`
          break;
        case 3:
          const totalLen = vm.serviceUrl.length
          const random = Math.floor(Math.random() * totalLen);
          url = vm.serviceUrl[random];
          break;
        default:
          break;
      }
      window.location.href = url;
    },
    formatUrl(url){
      return url.replace('https://', 'http://')
    }
  },
};
</script>

<style lang="scss" scoped>
.cr {
  $this: &;

  position: relative;
  justify-content: space-between;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include mediaquery_phone {
    background-size: cover;
  }

  .header {
    position: absolute;
    z-index: 2;
    display: none;
    width: 100%;
    height: 48px;
    background: #005a40;

    @include mediaquery_minipad {
      @include flex-center;
    }

    @include mediaquery_phone {
      min-height: 40px;
    }

    img {
      height: 100%;
    }
  }

  .pc {
    height: 100%;
    overflow: hidden;

    .bg-url {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 100%;
      }
    }

    .view {
      display: flex;
      justify-content: center;
      max-width: 100%;
      height: calc(100% - 150px);
      margin: 0 auto;
      padding: 0 165px;

      @include mediaquery_minipc {
        padding: 0 120px;
      }

      .phone {
        width: 30%;
        margin-top: 30px;

        img {
          width: 75%;
        }

        @include mediaquery_pc {
          display: none;
        }
      }

      .middle {
        position: relative;
        width: 30%;
        height: 100%;

        @include mediaquery_pc {
          width: 50%;
        }

        .sign {
          position: absolute;
          top: 130px;
          left: -40px;

          &-img {
            width: 100px;
            margin-bottom: 10px;

            img {
              width: 100%;
            }
          }

          &-text {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: 14px;
            opacity: 0.5;
          }
        }

        .people {
          width: 450px;
          height: 100%;

          img {
            width: 100%;
          }
        }
      }

      .right {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;

        @include mediaquery_pc {
          width: 50%;
        }

        @include mediaquery_pad {
          width: 60%;
        }

        &-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 80px;
          color: #fff;

          &-logo {
            width: 180px;
            margin-bottom: 20px;

            img {
              width: 100%;
            }

            @include mediaquery_minipc {
              width: 150px;
            }
          }

          &-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            .first {
              font-size: 32px;

              @include mediaquery_minipc {
                font-size: 28px;
              }

              @include mediaquery_pad {
                font-size: 24px;
              }
            }

            .second {
              margin: 10px 0 20px;
              color: #fee819;
              font-weight: bold;
              font-size: 48px;

              @include mediaquery_minipc {
                font-size: 44px;
              }

              @include mediaquery_pad {
                font-size: 40px;
              }
            }

            .third {
              font-size: 20px;
              line-height: 1.5;
              text-align: center;

              @include mediaquery_minipc {
                font-size: 16px;
              }
            }
          }

          &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 49px;
            margin-top: 40px;
            border-radius: 25px;
            overflow: hidden;
            color: #000;
            font-weight: bold;
            font-size: 20px;
            background: transparent linear-gradient(2deg, #daa71a 0%, #fec521 100%) 0% 0% no-repeat padding-box;
            cursor: pointer;
            box-shadow: 0 3px 6px #0003;

            @include mediaquery_minipc {
              width: 180;
              font-size: 16px;
            }
          }
        }

        &-bottom {
          width: 100%;

          &-first {
            display: flex;
            align-items: center;
            justify-content: center;

            &.first {
              position: relative;
              margin-bottom: 20px;
            }

            &-item {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 258px;
              height: 124px;
              padding: 0 20px;
              border: 1px solid #fff3;
              border-radius: 10px;
              color: #fff;
              background: rgba(0, 0, 0, 0.2);
              cursor: pointer;
              box-shadow: 0 3px 6px #0003;

              @include mediaquery_minipc {
                width: 208px;
              }

              @include mediaquery_ipad {
                padding: 0 10px;
              }

              &:first-child {
                margin-right: 20px;
              }

              &:hover {
                border: 1px solid #ffb31a80;
                color: #ffb31a;
              }

              .icon {
                width: 40px;
                height: 40px;
                margin-right: 20px;

                img {
                  width: 100%;
                }

                @include mediaquery_minipc {
                  margin-right: 10px;
                }
              }

              .text {
                &-first {
                  margin-bottom: 5px;
                  font-size: 24px;

                  @include mediaquery_minipc {
                    font-size: 20px;
                  }

                  @include mediaquery_ipad {
                    font-size: 16px;
                  }
                }

                &-second {
                  color: #fff;
                  font-size: 16px;
                  opacity: 0.6;

                  @include mediaquery_minipc {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 170px;
      padding: 100px 0 0 110px;
      color: #fff;
      font-size: 14px;
      background: transparent linear-gradient(180deg, #0000 0%, #0009 100%) 0% 0% no-repeat padding-box;

      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;

        .first, .third {
          width: 45px;
        }

        .second {
          width: 21px;
          margin: 0 10px;
        }

        img {
          width: 100%;
        }

        .text {
          margin-left: 15px;
          line-height: 1.5;
          opacity: 0.5;
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        height: 45px;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        line-height: 1.5;
        opacity: 0.5;
      }
    }

    @include mediaquery_minipad {
      display: none;
    }
  }

  .mobile {
    display: none;
    height: 100%;
    overflow: hidden;

    @include mediaquery_minipad {
      display: block;
    }

    .bullet-wrap {
      height: 145px;
    }

    .view {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 20px;

      @include mediaquery_phone {
        justify-content: unset;
      }

      .sign {
        position: absolute;
        top: 220px;
        right: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include mediaquery_phone {
          top: 200px;
          right: 30px;
        }

        &-img {
          width: 110px;
          margin: 0 auto;
          margin-bottom: 10px;

          img {
            width: 100%;
          }

          @include mediaquery_phone {
            width: 71px;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          color: #fff;
          font-size: 14px;
          opacity: 0.5;
        }

        &-logo {
          display: flex;
          margin-top: 15px;

          .first, .third {
            width: 32px;
          }

          .second {
            width: 15px;
            margin: 0 7px;
          }
        }
      }

      .people {
        z-index: 2;
        width: 373px;
        height: 100%;

        img {
          width: 100%;
        }

        @include mediaquery_phone {
          width: 258px;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      z-index: 3;
      width: 100%;
      height: 296px;
      color: #fff;
      background-size: contain;

      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        text-shadow: 0 2px 4px #00000080;

        .one {
          font-size: 16px;
        }

        .two {
          margin-top: 5px;
          color: #fee819;
          font-weight: bold;
          font-size: 24px;
        }
      }

      &-middle {
        width: 100%;

        &-first {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          &.first {
            margin-bottom: 10px;
          }

          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 62px;
            padding: 0 5px;
            border: 1px solid #fff3;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            box-shadow: 0 3px 6px #0003;

            &:hover {
              border: 1px solid #ffb31a80;
              color: #ffb31a;
            }

            &:first-child {
              margin-right: 10px;
            }

            .icon {
              width: 26px;
              margin-right: 10px;

              img {
                width: 100%;
              }
            }

            .text {
              display: flex;
              flex: 1 0 0;
              flex-direction: column;

              &-first {
                margin-bottom: 3px;
                font-size: 14px;
              }

              &-second {
                font-size: 12px;
                opacity: 0.6;
              }
            }
          }
        }
      }

      &-bottom {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }
}

.tooltip {
  position: absolute;
  right: 104%;
  bottom: -100%;
  z-index: 5;
  padding: 10px 20px 20px;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  background: #041415 0% 0% no-repeat padding-box;

  @include mediaquery_minipc {
    top: -420%;
    right: unset;
    bottom: unset;
  }

  @include mediaquery_pad {
    right: -50px;
  }

  @include mediaquery_minipad {
    top: unset;
    right: 0;
    bottom: 220px;
    left: 0;
    margin: 0 15px;
    transform: none;
  }

  &::before,
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';

    @include mediaquery_minipad {
      left: 25%;
    }
  }

  &::before {
    top: 62%;
    right: -20px;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ffffff4d;
  }

  &::after {
    top: 62%;
    right: -18px;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #041415;
  }

  @include mediaquery_minipc {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 140px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 140px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  @include mediaquery_pad {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 220px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 220px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  @include mediaquery_minipad {
    &::before {
      top: unset;
      right: unset;
      bottom: -19px;
      left: 85px;
      border-top: 10px solid #ffffff4d;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      top: unset;
      right: unset;
      bottom: -18px;
      left: 85px;
      border-top: 10px solid #041415;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }
  }

  .row {
    @include flex-expand;

    margin-top: 10px;
  }

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border: 1px solid #b8bfd2;
    border-radius: 2px;
    color: #333;
    background: #ecf2f8;

    @include mediaquery_minipad {
      height: 26px;
      padding: 6px 0;
    }
  }

  .ping {
    min-width: 80px;
    text-align: center;

    @include mediaquery_minipad {
      min-width: 56px;
    }
  }

  .url {
    flex: 1 0 0;
    min-width: 240px;
    padding: 8px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mediaquery_minipad {
      min-width: 120px;
      padding: 6px 10px;
    }
  }

  .arrow {
    width: 26px;
    margin: 0 12px;

    @include mediaquery_minipad {
      margin: 0 5px;
    }
  }

  .enter {
    width: 120px;
    margin-left: 20px;
    border-color: #ffc21a;
    color: #000;
    font-weight: bold;
    text-align: center;
    background: #ffc21a;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @include mediaquery_minipad {
      width: 74px;
      margin-left: 5px;
    }
  }
}

.toast {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  min-width: 80px;
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 12px #00000026;
  transform: translate3d(-50%, 0, 0);

  &-icon {
    width: 16px;
  }

  &-text {
    margin-left: 5px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
