import Api from '@/assets/js/api/api';

class SiteApi extends Api {
  static async getServiceUrl(locale) {
    const result = await this.callAxios('GET', '/core/system/frontend/customer-service/get', locale);
    return result;
  }

  static async getAppUrl(locale) {
    const result = await this.callAxios('GET', '/core/system/frontend/app-setting/download-link/get', locale);
    return result;
  }
}

export default SiteApi;
