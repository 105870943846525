import axios from 'axios';

class Api {
  static SERVER = '';

  static TOKEN;

  static async callAxios(method, url, params, contentType) {
    const headers = {};
    headers['Content-Type'] = contentType || 'application/json';

    try {
      const { status, data } = await axios({
        headers,
        method,
        url: this.SERVER + url,
        data: params,
      });
      return new Promise((resolve, reject) => {
        if (status === 200) {
          resolve(data);
        } else {
          reject(new Error(''));
        }
      });
    } catch (error) {
      return error;
    }
  }
}

export default Api;
