import Vue from 'vue';

const globalProperties = () => {
  Vue.prototype.$requireSafe = (path) => {
    try {
      /* eslint-disable */
      return require(`@/assets/img/${path}`);
      /* eslint-disable */
    } catch (err) {
      return undefined;
    }
  };
};

Vue.use(globalProperties);

export default globalProperties;
