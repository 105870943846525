export default {
  text: [
    '进来了 app速度挺快的！！',
    '这界面可以啊！',
    '终于找到一款能玩虚拟币的app了！！！',
    '游戏种类看著挺齐全啊',
    '我就问 视讯妹子正点不？',
    '有多币别充提渠道，这个可以！！',
    '入金审核倒是特别快啊 就看晚点出金行不行了',
    '老铁 这个我说句话 刚刚提现了 入账不用一分钟',
    '这app界面看著挺舒服~~~',
    '草！中大奖了！！',
    '捕鱼画面挺精致的',
    '全部身家押法国连庄！！给老子争气点啊！！！',
    '手痒了～～～',
    '来个豹子吧！！！！！！',
    '直接汇兑不用等，就是舒服！！',
    '劳资几千U要来翻本啦！！',
    '你们家体育水位挺高的啊',
    '客服小姐姐在不？我想拉人，能给我调下返点不？',
    '鱼鱼鱼鱼鱼鱼鱼鱼 好多鱼啊哈哈哈哈～～',
    '还行，能汇兑就给赞',
    '连十把庄了，有木有辣么邪门啊啊啊',
    '存U活动还挺划算的呀',
    '老虎机挺会爆奖啊 这都爆两回啦',
    '这虚拟币都能玩了？来试试',
    '真别说，现在跌成这样，来这试试手气总比放著发愁强！',
    '同意楼上',
    '1',
    '1',
    '1111111',
    'v8封顶了吗？没难度啊',
    '楼上你就吹吧！',
    '来张公吧！',
    '666～～瞇啥来啥～～',
    '我梭哈，你随意，哈哈哈哈',
    '最爱捕鱼机～～',
    '这app能存U提RMB吗？？',
    '能',
    '百家乐小姐姐挺水灵呀',
    '免费旋转66666～～',
    '不夸张，提现到账不用1分钟，挺神奇的啊！',
    '能玩能换汇，现在都这么先进了的吗？',
    '又爆奖啦，捕鱼666',
    '跪求世足明灯！！！',
    '厉害了我的哥！！',
    '钱都让你赢走了，我们只能填坑啊',
    '以币养币，稳得一批～',
    '商量下能不能给我赢次大的',
    '稳住，别浪，心态决定成败',
    '别问有什么币，问就是牛币！',
    '有点意思，先点个赞',
    '先搞个一万U试试水',
    '支持鼻圈！！',
    'UB都卖了才看到这玩意儿…',
    '没币，心累啊',
    '你说出我想说的话',
    '666这个可以有！',
    '支持！要是早点推出就好了',
    '充，都充，哪次不充？',
    '有啥推荐玩的？',
    '充了5万U，走起勒～',
    '牛轻人终究还是牛轻人，牛逼啊',
    '不错，很好',
    '测试了下，充提都正常 赌狗走起！',
    '你知道这世上最讽刺的一件事是什么吗？就是你努力让自己变得重情重义、有趣、有爱。可突然之间你发现，你只要有钱就行了。',
    '好东西',
    '文化人',
    '不错，有U先推个',
    '村U有啥优惠不？',
    '咋才能当代理啊？',
    '穷得只剩U了',
    '可充可提还能玩，币圈福音啊～～～',
    '支持，能游戏能汇兑，还不错',
    '搞些u来玩玩也香啊',
    '有老司机能开开课，带带萌新吗？',
    '愿世间所有美好，都恰逢其时。',
    '牛逼！！！！',
    '稳扎稳打别上头！',
    '画面简洁大气，还行',
    '银行卡封光了，只剩U币咯',
    '晋级有啥特别优惠吗？',
    '给个机会游上岸吧…',
    '幸运女神眷顾我一次吧！！！',
    '充了一万U，开干啦！',
    '悠著点老哥',
    '电子百家双丰收，财神到啦！！',
    '电子玩的啥，爆的挺厉害的啊',
    '66666吸吸欧气',
    '真的挺屌哦',
    '提了U，先上岸了',
    '舒舒服服赢一波！',
    '稳住才是真理，存个余额宝',
    '干就完事啦 行动支持中国男篮！！',
    '活动多多就是好 有U就舒服',
    '可以出U，挺不错的',
    '牛逼',
    '矮油 66666',
    '幸好币都没卖 准备起飞啦',
    '砸锅卖铁买的币 终于不用忍痛认赔啦',
    'U跟RMB能互转是真的不错，推一个',
    '搏一搏 万一中了呢',
    '稳如20u',
  ],
};
