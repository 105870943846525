export default {
  copyright: 'COPYRIGHT © 2022 {renderHostName}',
  copyright2: 'All Rights Reserved.',
  footerText1: '国际蓝联',
  footerText2: '全球合作伙伴',
  text1: '2022 FIFA世界杯 更多精彩',
  text2: '尽在',
  text3: '这绚烂的殿堂 唯有无比的信念 永不言败',
  text4: '才能为梦想的金杯 涂上荣耀的色彩',
  spokesperson: '代言人',
  spokesperson1: '卡里姆 · 本泽马',
  rightNow: '立即开始',
  homePage: '进入官网',
  download: 'APP下载',
  register: '立即注册',
  service: '在线客服',
  close: '关闭',
  enter: '立即进入',
};
